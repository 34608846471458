import { Link } from '@luminovo/design-system';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';

export function NegotiationIpnLabel({ ipn }: { ipn?: string | null }): JSX.Element {
    const { openDrawer } = useIpnDetailsDrawer();
    if (!ipn) {
        return <span>-</span>;
    }

    return (
        <Link onClick={() => openDrawer({ ipnId: ipn, rfqContext: { type: 'OutsideRfQ' } })} attention="high">
            {ipn}
        </Link>
    );
}
