import { isPresent } from '@luminovo/commons';
import { FieldSelect, Flexbox, Text } from '@luminovo/design-system';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { AwardScenario } from '../hooks/queries';
import { useAwardScenarios } from '../hooks/useAwardScenarios';
import { formatAwardScenario } from '../model/awardScenario';

export function FieldSelectAwardScenario({
    negotiationId,
    onChange,
    value,
}: {
    negotiationId: number;
    onChange: (value: AwardScenario | null) => void;
    value: AwardScenario | null;
}) {
    const { data: awardScenarios = [] } = useAwardScenarios(negotiationId);
    return (
        <FieldSelect
            style={{ minWidth: 260 }}
            onChange={onChange}
            options={awardScenarios}
            value={value}
            renderOption={(awardScenario) => {
                return (
                    <Flexbox sx={{ justifyContent: 'space-between', width: '100%' }}>
                        <Text>{formatAwardScenario(awardScenario)}</Text>
                        <Text>{awardScenario.awardedOffers.length}</Text>
                    </Flexbox>
                );
            }}
            isOptionEqualToValue={(awardScenario, value) => awardScenario.id === value.id}
            getOptionKey={(awardScenario) => awardScenario.id.toString()}
            getOptionLabel={formatAwardScenario}
        />
    );
}

function useQueryParamState(queryParamName: string): [string | null, (value: string | null) => void] {
    const { search } = useLocation();
    const history = useHistory();
    const value = new URLSearchParams(search).get(queryParamName);

    const setValue = React.useCallback(
        (value: string | null) => {
            const searchParams = new URLSearchParams(search);
            if (value) {
                searchParams.set(queryParamName, value);
            } else {
                searchParams.delete(queryParamName);
            }
            history.replace({ search: searchParams.toString() });
        },
        [queryParamName, history, search],
    );

    return [value, setValue];
}

export function useAwardScenarioQueryParam(
    negotiationId: number,
    type: 'referenceScenarioId' | 'selectedScenarioId',
): [AwardScenario | null, (value: AwardScenario | null) => void] {
    const [id, setId] = useQueryParamState(type);
    const { data: awardScenarios = [] } = useAwardScenarios(negotiationId);

    const idOrDefault = isPresent(id) ? Number(id) : awardScenarios[0]?.id;

    const value = awardScenarios.find((awardScenario) => awardScenario.id === idOrDefault) ?? null;

    return [value, (value: AwardScenario | null) => setId(value?.id?.toString() ?? null)];
}
