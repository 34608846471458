import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { QuoteRequestLineItem } from '../hooks/queries';

export enum QuoteRequestLineItemStatus {
    NotSent = 'not-sent',
    Sent = 'sent',
    Received = 'received',
}

export const quoteRequestLineItemStatusTranslations: Record<QuoteRequestLineItemStatus, MessageDescriptor> = {
    [QuoteRequestLineItemStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestLineItemStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestLineItemStatus.Received]: defineMessage({ message: 'Received' }),
};
export function getQuoteRequestLineItemStatus(quoteRequestLineItem: QuoteRequestLineItem): QuoteRequestLineItemStatus {
    if (!quoteRequestLineItem.quoteRequest) {
        return QuoteRequestLineItemStatus.NotSent;
    }
    if (quoteRequestLineItem.quoteRequest.sent_date) {
        return QuoteRequestLineItemStatus.Sent;
    }
    if (quoteRequestLineItem.quoteRequest.received_date) {
        return QuoteRequestLineItemStatus.Received;
    }
    return QuoteRequestLineItemStatus.NotSent;
}
