/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogActions,
    FieldMultiSelectControlled,
    FieldSelect,
    FieldText,
    Flexbox,
    InteractiveCard,
    SecondaryButton,
    SecondaryIconButton,
    TertiaryIconButton,
    Text,
    XlsIcon,
} from '@luminovo/design-system';
import { EmailTemplateDTO } from '@luminovo/http-client';
import { renderTemplate, RichTextEditorInput } from '@luminovo/rich-text-editor';
import { Add, Check, Close, GetApp } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Fade } from '@mui/material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useDownloadQuoteRequestExcel } from '../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import {
    useSupplierContacts,
    useSupplierContactsFromSupplier,
} from '../../../resources/supplierContact/supplierContactHandler';
import { useAllOrganizationUsers } from '../../../resources/user/userHandler';
import { createVars, VarId } from '../../Sourcing/components/QuoteExporter/createVars';
import { getQuoteRequestEmailTemplateBySupplier } from '../../Sourcing/components/QuoteExporter/getQuoteRequestEmailTemplateBySupplier';
import { QuoteRequest } from '../hooks/queries';
import { useInvalidateSynthqlTable } from '../hooks/useInvalidateSynthqlTable';

type FormValues = {
    include_pcb_specification: boolean;
    include_shipping_panel_specification: boolean;
    include_mail_attachments: boolean;
    mail_data: {
        email: {
            template?: EmailTemplateDTO;
            subject: string;
            body: string;
        };
        quote_request_id: number;
        recipient_emails: Array<{ email: string; first_name: string; last_name: string }>;
        cc_emails: Array<{ email: string; name: string }>;
    }[];
};

export function DialogPreviewQuoteRequestEmail({
    quoteRequests,
    onClose,
}: {
    quoteRequests: QuoteRequest[];
    onClose: () => void;
}) {
    const invalidate = useInvalidateSynthqlTable(
        'quote_request',
        'quote_request_line_item',
        'negotiation',
        'negotiation_line_item',
    );
    const { mutateAsync: sendEmails } = useHttpMutation('POST /quote-request/send-many', {
        snackbarMessage: 'Email sent',
        onSuccess: () => {
            invalidate();
        },
    });

    const { user, organization } = useCurrentUserDetailsContext();
    const { data: supplierContacts } = useSupplierContacts({ refetchOnWindowFocus: true, suspense: true });

    const { data: templates = [] } = useHttpQuery(
        'GET /email-template',
        {},
        {
            suspense: true,
            select: (res) => res.items,
        },
    );

    const isSingleQuoteRequest = quoteRequests.length === 1;

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const quoteRequest = quoteRequests[selectedIndex];

    if (quoteRequests.length === 0 || !supplierContacts) {
        return <></>;
    }

    const defaultValues: FormValues = {
        include_pcb_specification: false,
        include_shipping_panel_specification: false,
        include_mail_attachments: true,
        mail_data: quoteRequests.map((quoteRequest) => {
            const template = getQuoteRequestEmailTemplateBySupplier(templates, quoteRequest.supplier?.id);

            const recipient = supplierContacts
                .filter((c) => c.supplier === quoteRequest.supplier?.id)
                .map((c) => ({
                    email: c.email,
                    first_name: c.first_name,
                    last_name: c.last_name,
                }))
                .slice(0, 1);

            const { subject, body } = renderQuoteRequestEmailTemplate({
                template,
                quoteRequest,
                recipient: recipient[0],
                sender: user,
                organization,
            });

            return {
                email: { subject, body, template },
                quote_request_id: quoteRequest.id,
                recipient_emails: recipient,
                cc_emails: [{ email: user.email, name: `${user.first_name} ${user.last_name}` }],
            };
        }),
    };

    return (
        <Dialog
            title={`Send quote request`}
            maxWidth={isSingleQuoteRequest ? 'md' : 'lg'}
            open={true}
            onClose={onClose}
        >
            <Divider />
            <FormContainer
                defaultValues={defaultValues}
                onSubmit={async (form) => {
                    await sendEmails({
                        requestBody: {
                            include_mail_attachments: form.include_mail_attachments,
                            include_pcb_specification: form.include_pcb_specification,
                            include_shipping_panel_specification: form.include_shipping_panel_specification,
                            mail_data: form.mail_data.map((mail) => {
                                return {
                                    email: { subject: mail.email.subject, body: mail.email.body },
                                    quote_request_id: mail.quote_request_id,
                                    recipient_emails: mail.recipient_emails.map((recipient) => {
                                        return {
                                            email: recipient.email,
                                            name: `${recipient.first_name} ${recipient.last_name}`,
                                        };
                                    }),
                                    cc_emails: mail.cc_emails,
                                };
                            }),
                        },
                    });
                    onClose();
                }}
            >
                <SidebarLayout
                    hideSidebar={isSingleQuoteRequest}
                    sidebar={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                padding: 2,
                                paddingX: 3,
                                paddingTop: 1.5,
                            }}
                        >
                            {quoteRequests.map((quoteRequest, index) => (
                                <InteractiveCard
                                    key={index}
                                    onClick={() => setSelectedIndex(index)}
                                    selected={index === selectedIndex}
                                    sx={{
                                        borderRadius: 1,
                                        width: '100%',
                                        display: 'grid',
                                        gridTemplateColumns: '1fr auto',
                                        rowGap: '2px',
                                        columnGap: 2,
                                    }}
                                >
                                    <Text variant="body-semibold">{quoteRequest.supplier?.name}</Text>
                                    <Text color={colorSystem.neutral[6]} variant="body">
                                        {quoteRequest.lineItems.length} line items
                                    </Text>

                                    <MissingRecipientLabel index={index} />
                                </InteractiveCard>
                            ))}
                        </Box>
                    }
                    content={
                        <Box
                            // Unmounts the form items when the selected quote request changes
                            key={selectedIndex}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                alignItems: 'center',
                                gap: 2,
                                padding: 2,
                                paddingX: 3,
                            }}
                        >
                            <FormItemEmailTemplate index={selectedIndex} quoteRequest={quoteRequest} />
                            <FormItemRecipients index={selectedIndex} quoteRequest={quoteRequest} />
                            <FormItemCcs index={selectedIndex} quoteRequest={quoteRequest} />
                            <FormItemSubject index={selectedIndex} quoteRequest={quoteRequest} />
                            <FormItemBody index={selectedIndex} quoteRequest={quoteRequest} />
                            <FormItemAttachments quoteRequest={quoteRequest} />
                        </Box>
                    }
                />

                <DialogActions
                    sx={{
                        borderTop: `1px solid ${colorSystem.neutral[2]}`,
                        backgroundColor: 'white',
                    }}
                >
                    <SubmitButton label={isSingleQuoteRequest ? `Send email` : `Send ${quoteRequests.length} emails`} />
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

function SidebarLayout({
    sidebar,
    content,
    hideSidebar,
}: {
    sidebar: React.ReactNode;
    content: React.ReactNode;
    hideSidebar: boolean;
}) {
    const maxHeight = '70vh';

    if (hideSidebar) {
        return <Box sx={{ overflowY: 'auto', maxHeight }}>{content}</Box>;
    }
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1px 1fr',
                position: 'relative',
                maxHeight,
            }}
        >
            <Box sx={{ overflowY: 'auto', maxHeight }}>{sidebar}</Box>
            <Divider orientation="vertical" style={{ height: '100%' }} />
            <Box sx={{ overflowY: 'auto', maxHeight }}>{content}</Box>
        </Box>
    );
}

function MissingRecipientLabel({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();

    return (
        <Controller
            control={control}
            name={`mail_data.${index}.recipient_emails`}
            rules={{
                validate: (value) => {
                    if (!value) {
                        return true;
                    }
                    if (value.length > 0) {
                        return true;
                    }
                    return 'At least one recipient is required';
                },
            }}
            render={({ field: { value } }) => {
                if (!value) {
                    return <Text color={colorSystem.neutral[6]}>Skipped</Text>;
                }
                if (value.length > 0) {
                    return <></>;
                }
                return <Text color={colorSystem.yellow[7]}>No recipient</Text>;
            }}
        />
    );
}

function FormItemRecipients({ index, quoteRequest }: { index: number; quoteRequest: QuoteRequest }) {
    const { control } = useFormContext<FormValues>();
    const [open, setOpen] = React.useState(false);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');

    const { mutateAsync: createContact, isLoading } = useHttpMutation('POST /supplier-contacts', {
        snackbarMessage: 'Contact added',
        onSuccess: () => {
            setFirstName('');
            setLastName('');
            setEmail('');
            setOpen(false);
        },
    });

    const { data: contacts = [] } = useSupplierContactsFromSupplier(quoteRequest.supplier?.id);

    const emailContacts = contacts.map((contact) => ({
        email: contact.email,
        first_name: contact.first_name,
        last_name: contact.last_name,
    }));

    const timeout = 400;

    return (
        <FormItem key={index} label="Recipients">
            <Fade in={!open} timeout={timeout} hidden={open} unmountOnExit appear={false}>
                <div>
                    <Flexbox gap={8} alignItems={'center'}>
                        <FieldMultiSelectControlled
                            control={control}
                            required
                            name={`mail_data.${index}.recipient_emails`}
                            FieldProps={{
                                fullWidth: true,
                                options: emailContacts,
                                getOptionLabel: (contact) =>
                                    `${contact.first_name} ${contact.last_name} <${contact.email}>`,
                                getOptionKey: (contact) => contact.email,
                            }}
                        />
                        <SecondaryButton
                            startIcon={<Add />}
                            style={{ height: '38px' }}
                            size="medium"
                            onClick={() => setOpen(true)}
                        >
                            Add
                        </SecondaryButton>
                    </Flexbox>
                </div>
            </Fade>

            <Fade in={open} timeout={timeout} hidden={!open} unmountOnExit>
                <div>
                    <Flexbox gap={8} padding="8px" sx={{ background: colorSystem.primary[1] }}>
                        <FieldText
                            value={firstName}
                            onChange={(text) => setFirstName(text ?? '')}
                            fullWidth
                            placeholder="First name"
                        />
                        <FieldText
                            value={lastName}
                            onChange={(text) => setLastName(text ?? '')}
                            fullWidth
                            placeholder="Last name"
                        />

                        <FieldText
                            value={email}
                            onChange={(text) => setEmail(text ?? '')}
                            fullWidth
                            placeholder="Email"
                        />

                        <SecondaryIconButton
                            style={{ height: '40px' }}
                            size="large"
                            disabled={!firstName || !lastName || !email}
                            onClick={() =>
                                createContact({
                                    requestBody: {
                                        first_name: firstName,
                                        last_name: lastName,
                                        email,
                                        position: null,
                                        user_language: 'en',
                                        supplier: quoteRequest.supplier?.id!,
                                        is_main_contact: false,
                                    },
                                })
                            }
                        >
                            {isLoading ? <CircularProgress size="16px" /> : <Check fontSize="inherit" />}
                        </SecondaryIconButton>
                        <SecondaryIconButton style={{ height: '40px' }} size="large" onClick={() => setOpen(false)}>
                            <Close fontSize="inherit" />
                        </SecondaryIconButton>
                    </Flexbox>
                </div>
            </Fade>
        </FormItem>
    );
}

function FormItemCcs({ index, quoteRequest }: { index: number; quoteRequest: QuoteRequest }) {
    const { control } = useFormContext<FormValues>();

    const { data: contacts = [] } = useAllOrganizationUsers();

    const emailContacts = contacts.map((contact) => ({
        email: contact.email,
        name: `${contact.first_name} ${contact.last_name}`,
    }));

    return (
        <FormItem label="CCs">
            <FieldMultiSelectControlled
                control={control}
                name={`mail_data.${index}.cc_emails`}
                FieldProps={{
                    options: emailContacts,
                    getOptionLabel: (contact) => `${contact.name} <${contact.email}>`,
                    getOptionKey: (contact) => contact.email,
                }}
            />
        </FormItem>
    );
}

function FormItemBody({ index, quoteRequest }: { index: number; quoteRequest: QuoteRequest }) {
    const { control } = useFormContext<FormValues>();

    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Subject</Trans>
            </Text>
            <Controller
                control={control}
                name={`mail_data.${index}.email.body`}
                render={({ field }) => {
                    return (
                        <RichTextEditorInput
                            key={templateId}
                            namespace="quote-requests::subject"
                            vars={createVars()}
                            hideVariables
                            initialHtml={field.value}
                            onChange={field.onChange}
                            autosaveEveryMillis={200}
                        />
                    );
                }}
            />
        </>
    );
}

function FormItemAttachments({ quoteRequest }: { quoteRequest: QuoteRequest }) {
    const { mutateAsync } = useDownloadQuoteRequestExcel(quoteRequest.id);

    return (
        <FormItem label="Attachments">
            <Flexbox
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: colorSystem.primary[1] } }}
                onClick={() => mutateAsync()}
                justifyContent="space-between"
                alignItems={'center'}
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'4px'}
            >
                <Flexbox gap={8} paddingX={'12px'} alignItems={'center'}>
                    <XlsIcon overflow={'inherit'} />
                    <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                        {`${quoteRequest.supplier?.name}.xls •
                        ${quoteRequest.lineItems.length}
                        ${quoteRequest.lineItems.length === 1 ? 'part' : 'parts'}`}
                    </Text>
                </Flexbox>

                <TertiaryIconButton>
                    <GetApp fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>
        </FormItem>
    );
}

function FormItemSubject({ index, quoteRequest }: { index: number; quoteRequest: QuoteRequest }): JSX.Element {
    const { control } = useFormContext<FormValues>();

    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Subject</Trans>
            </Text>
            <Controller
                control={control}
                name={`mail_data.${index}.email.subject`}
                render={({ field }) => {
                    return (
                        <RichTextEditorInput
                            key={templateId}
                            namespace="quote-requests::subject"
                            vars={createVars()}
                            hideVariables
                            initialHtml={field.value}
                            onChange={field.onChange}
                            autosaveEveryMillis={200}
                        />
                    );
                }}
            />
        </>
    );
}

function FormItemEmailTemplate({ index, quoteRequest }: { index: number; quoteRequest: QuoteRequest }): JSX.Element {
    const { control, setValue } = useFormContext<FormValues>();

    const { data: emailTemplates = [] } = useHttpQuery(
        'GET /email-template',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => Array.from(res.items).sort((a, b) => a.name.localeCompare(b.name)),
        },
    );

    const renderTemplate = useRenderQuoteRequestEmailTemplate({ index, quoteRequest });
    const none = t`None`;
    return (
        <FormItem key={index} label="Template">
            <Controller
                control={control}
                name={`mail_data.${index}.email.template`}
                render={({ field }) => {
                    return (
                        <FieldSelect<EmailTemplateDTO>
                            options={emailTemplates}
                            value={field.value ?? null}
                            onChange={(template: EmailTemplateDTO | null) => {
                                field.onChange(template);
                                const { subject, body } = renderTemplate(template ?? undefined);
                                setValue(`mail_data.${index}.email.subject`, subject);
                                setValue(`mail_data.${index}.email.body`, body);
                            }}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            getOptionKey={(t) => t?.id ?? none}
                            getOptionLabel={(t) => t?.name ?? none}
                        />
                    );
                }}
            />
        </FormItem>
    );
}

function FormItem({ label, children }: { label: string; children: React.ReactNode }) {
    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                {label}
            </Text>
            {children}
        </>
    );
}

export function useRenderQuoteRequestEmailTemplate({
    quoteRequest,
    index,
}: {
    quoteRequest: QuoteRequest;
    index: number;
}) {
    const { user: sender, organization } = useCurrentUserDetailsContext();
    const { control } = useFormContext<FormValues>();

    const recipients = useWatch({ control, name: `mail_data.${index}.recipient_emails` }) ?? [];

    return (template?: EmailTemplateDTO) => {
        return renderQuoteRequestEmailTemplate({
            template,
            quoteRequest,
            sender,
            organization,
            recipient: recipients[0],
        });
    };
}

function renderQuoteRequestEmailTemplate({
    template,
    quoteRequest,
    sender,
    organization,
    recipient,
}: {
    template?: EmailTemplateDTO;
    quoteRequest: QuoteRequest;
    sender?: { first_name: string; last_name: string };
    recipient?: { first_name: string; last_name: string };
    organization?: { name: string };
}) {
    if (!template) {
        return { subject: '', body: '' };
    }

    const context: Record<VarId, string> = {
        'customer.name': '',
        'quote_request.due_date': quoteRequest.due_date ?? '',
        'quote_request.number': String(quoteRequest.id),
        'quote_request.notes': '',
        'quote_request.send_date': formatToLongDate(new Date()),
        'quote_request.supplier_portal_link': 'not supported yet',
        'rfq.name': 'not supported yet',
        'rfq.number': 'not supported yet',
        'sender.first_name': sender?.first_name ?? '',
        'sender.last_name': sender?.last_name ?? '',
        'sender.organization.name': organization?.name ?? '',
        'supplier.name': quoteRequest.supplier?.name ?? '',
        'recipient.first_name': recipient?.first_name ?? '',
        'recipient.last_name': recipient?.last_name ?? '',
    };

    const subject = renderTemplate(template.subject, context, { asText: true });
    const body = renderTemplate(template.body, context, { asText: false });

    return { subject, body };
}
