import { transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { QuoteRequest } from '../hooks/queries';
import {
    getQuoteRequestStatus,
    QuoteRequestStatus,
    quoteRequestStatusTranslations,
} from '../model/getQuoteRequestStatus';

export function QuoteRequestStatusChip({ quoteRequest }: { quoteRequest: QuoteRequest }) {
    const status = getQuoteRequestStatus(quoteRequest);
    return (
        <StatusChip
            color={getQuoteRequestStatusColor(status)}
            label={transEnum(status, quoteRequestStatusTranslations)}
        />
    );
}

export function getQuoteRequestStatusColor(status: QuoteRequestStatus) {
    switch (status) {
        case QuoteRequestStatus.NotSent:
            return 'neutral';
        case QuoteRequestStatus.Sent:
            return 'blue';
        case QuoteRequestStatus.Overdue:
            return 'red';
        case QuoteRequestStatus.Discarded:
            return 'yellow';
        case QuoteRequestStatus.Received:
            return 'green';
    }
}
