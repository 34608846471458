import { ColumnTagsSheetsState } from './types';

const columnMappingStorageKey = 'luminovo.bom-importer.column-mapping';

export function saveColumnMapping({ tableState }: { tableState: ColumnTagsSheetsState }): void {
    sessionStorage.setItem(columnMappingStorageKey, JSON.stringify(tableState));
}

export const defaultTagsState: ColumnTagsSheetsState = { sheets: [{ tags: [] }], selectedSheet: 0, hasChanged: false };

/**
 * @returns the column mapping of the last BOM that the user attempted to import from the session storage.
 *   If no mapping is found, it returns the default state.
 *
 *   Used in the "remember column mapping" feature in the case where the user does some work in the column mapping
 *   closes the bom importer to edit the Excel and re-imports the BOM again.
 */
export function loadColumnMapping(): ColumnTagsSheetsState {
    const result = sessionStorage.getItem(columnMappingStorageKey);

    if (!result) {
        return defaultTagsState;
    }

    return JSON.parse(result);
}
