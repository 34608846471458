import { Flexbox, RightBoxDrawer } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { IpnDetailsPanel } from '../../../modules/PartLibrary/IpnDetailsPage/components/IpnDetailsPanel';
import { CloseDrawerButton, useDrawerContext } from '../../contexts/ModalContext';

export function useIpnDetailsDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} padding={'12px 24px 12px 16px'} width={'480px'}>
                        <IpnDetailsPanel
                            ipnId={ipnId}
                            rfqContext={rfqContext}
                            BackButton={() => <CloseDrawerButton disableMargin={true} />}
                            showOpenIpnPageButton={false}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
