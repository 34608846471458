import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';

export const negotiationEndpoints = {
    // TODO(negotiations): move to quote-request-line-item endpoints
    'POST /quote-request/draft': endpoint({
        description: 'Creates a quote request draft',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            negotiation_line_item_ids: r.Array(r.Number),
            supplier_ids: r.Array(r.String),
        }),
        responseBody: r.Unknown,
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'GET /quote-request/:id/download-excel': endpoint({
        description: 'Downloads a quote request as an Excel file',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    // TODO(negotiations): move to award-scenario endpoints
    'POST /award-scenario': endpoint({
        description: 'Creates an award scenario',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Unknown,
        requestBody: r.Record({
            name: r.String,
            negotiation_id: r.Number,
            clone_from: r.Number.optional(),
        }),
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /quote-request/send-many': endpoint({
        description: 'Sends multiple quote requests',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            include_pcb_specification: r.Boolean,
            include_shipping_panel_specification: r.Boolean,
            include_mail_attachments: r.Boolean,
            mail_data: r.Array(
                r.Record({
                    quote_request_id: r.Number,
                    recipient_emails: r.Array(r.Record({ name: r.String.optional(), email: r.String })),
                    cc_emails: r.Array(r.Record({ name: r.String, email: r.String })),
                    email: r.Record({ subject: r.String, body: r.String }),
                }),
            ),
        }),
        responseBody: r.Unknown,
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'PATCH /quote-request/:id': endpoint({
        description: 'Updates a quote request',
        pathParams: r.Record({
            id: r.Number,
        }),
        responseBody: r.Unknown,
        queryParams: r.Undefined,
        requestBody: r.Record({
            due_date: r.String.optional(),
            discarded: r.Boolean.optional(),
            show_customer: r.Boolean.optional(),
            show_target_price: r.Boolean.optional(),
        }),
    }),

    'POST /negotiation': endpoint({
        description: 'Creates a negotiation',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            name: r.String,
            demand_ids: r.Array(r.String),
            filter_by_categories: r.Array(
                r.Union(
                    r.Record({ type: r.Literal('OtsCategory'), id: r.Number }),
                    r.Record({ type: r.Literal('CustomPartType'), data: r.String }),
                ),
            ),
            filter_manufacturers: r.Array(r.String),
            filter_not_quoted_since: r.String.nullable(),
            group_by_customer: r.Boolean,
            group_by_site_groups: r.Array(r.Array(r.String)),
            group_by_supplier_groups: r.Array(r.Array(r.String)),
            group_by_sourcing_scenario: r.Boolean,
        }),
        responseBody: r.Record({
            id: r.Number,
        }),
    }),

    'DELETE /negotiation/:id': endpoint({
        description: 'Deletes a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),
};
