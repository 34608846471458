import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { QuoteRequest } from '../hooks/queries';

export enum QuoteRequestStatus {
    NotSent = 'not-sent',
    Sent = 'sent',
    Overdue = 'overdue',
    Discarded = 'discarded',
    Received = 'received',
}

export const quoteRequestStatusTranslations: Record<QuoteRequestStatus, MessageDescriptor> = {
    [QuoteRequestStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestStatus.Overdue]: defineMessage({ message: 'Overdue' }),
    [QuoteRequestStatus.Discarded]: defineMessage({ message: 'Discarded' }),
    [QuoteRequestStatus.Received]: defineMessage({ message: 'Received' }),
};

export function getQuoteRequestStatus(quoteRequest: QuoteRequest): QuoteRequestStatus {
    if (quoteRequest.discarded) {
        return QuoteRequestStatus.Discarded;
    }
    if (quoteRequest.received_date) {
        return QuoteRequestStatus.Received;
    }

    if (quoteRequest.due_date) {
        const dueDate = new Date(quoteRequest.due_date);
        const now = new Date();
        if (dueDate < now) {
            return QuoteRequestStatus.Overdue;
        }
    }

    if (quoteRequest.sent_date) {
        return QuoteRequestStatus.Sent;
    }
    return QuoteRequestStatus.NotSent;
}
