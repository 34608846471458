import { PrimaryButton } from '@luminovo/design-system';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { QuoteRequest } from '../hooks/queries';
import { getQuoteRequestStatus } from '../model/getQuoteRequestStatus';
import { DialogPreviewQuoteRequestEmail } from './DialogPreviewQuoteRequestEmail';

export function ButtonSendQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequest[] }) {
    const sendable = quoteRequests.filter((s) => {
        const status = getQuoteRequestStatus(s);
        return status === 'not-sent';
    });

    const { setDialog, closeDialog } = useDialogContext();

    return (
        <>
            <PrimaryButton
                size="medium"
                disabled={sendable.length === 0}
                onClick={() => {
                    setDialog(<DialogPreviewQuoteRequestEmail quoteRequests={sendable} onClose={closeDialog} />);
                }}
            >
                {sendable.length > 1 ? `Send ${sendable.length} requests` : 'Send request'}
            </PrimaryButton>
        </>
    );
}
